import React from 'react';
import { Box } from '@mui/material';
import theme from "../theme";

const backgroundStyle = {
  background: theme.palette.background.medium,
  // set min height here if need to overflow
  minHeight: '100vh',
};

function BackgroundContainer({ children }) {
  return <Box sx={backgroundStyle}>{children}</Box>;
}

export default BackgroundContainer;
