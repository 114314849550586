import React from "react";
import { Grid, Typography, Divider, SvgIcon } from "@mui/material";
import { mdilDiamondStone, mdilAccount, mdilFlask } from "@mdi/light-js";
import theme from "../theme";

const Service = ({ icon, overline, text }) => (
  <Grid item xs={12} sm={4}>
    <SvgIcon
      viewBox="0 0 22 24"
      style={{
        fontSize: "2rem",
        color: theme.palette.text.main,
        border: `1px solid ${theme.palette.text.main}`,
        borderRadius: "50%",
        padding: "4px",
      }}
    >
      <path d={icon} />
    </SvgIcon>
    <Typography variant="overline" style={{display: 'block' , lineHeight: '1.66'}} gutterBottom>
      {overline}
    </Typography>
    <Divider
      sx={{
        width: "25%",
        backgroundColor: theme.palette.primary.main,
        height: "1px",
        marginBottom: "1rem",
      }}
    />
    <Typography variant="body">{text}</Typography>
  </Grid>
);

const ServicesSection = () => {
  return (
    <Grid
      container
      spacing={0}
      maxWidth={"lg"}
      sx={{
        backgroundColor: theme.palette.background.light,
        margin: "auto",
        pb: "64px",
      }}
    
    >
      <Grid container spacing={0} sx={{ pt: 5, pb: 8 }}>
        {/* Need to change svg viewbox to adjust for border */}
        <Grid item xs={12}>
          <Typography variant="h4">
            turning visions <br />
            <span
              style={{
                color: theme.palette.primary.main,
                fontWeight: "bold",
              }}
            >
              into reality.
            </span>{" "}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Service
          icon={mdilDiamondStone}
          overline="Investment"
          text="Asteroid actively engages in blockchain related investments; strategically identifying promising projects and startups to support financially. Investment spans across various niches of the blockchain industry."
        />
        <Service
          icon={mdilAccount}
          overline="Advisory"
          text="With a team of seasoned blockchain experts, Asteroid provides critical insights and strategic counsel to aid comprehensive decision making across the blockchain landscape."
        />
        <Service
          icon={mdilFlask}
          overline="Incubation"
          text="Partnering with Asteroid is not just funding. By leveraging a deep network of industry professionals while providing launch strategy and tokenomic design, Asteroid empowers startups to reach their full potential."
        />
      </Grid>
    </Grid>
  );
};

export default ServicesSection;
