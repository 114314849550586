import React from "react";
import { Grid, useMediaQuery, Typography} from "@mui/material";
import theme from "../theme";

// Logos need to be 1.2 aspect ratio (120 width 100 height)
const logos = [
  { src: "/images/portfolio_logos/celer_logo.png", alt: "Celer", company: 'Celer' },
  { src: "/images/portfolio_logos/fetch_logo.png", alt: "Fetch AI", company: 'Fetch AI' },
  { src: "/images/portfolio_logos/superfarm_logo.png", alt: "Superfarm", company: 'Superfarm' },
  { src: "/images/portfolio_logos/paid_logo.png", alt: "Paid Network", company: 'Paid Network' },
  { src: "/images/portfolio_logos/pegaxy_logo.png", alt: "Pegaxy", company: 'Pegaxy' },
  { src: "/images/portfolio_logos/mixmob_logo.png", alt: "MixMob", company: 'MixMob' },
  { src: "/images/portfolio_logos/sidus_logo.png", alt: "Sidus Heroes", company: 'Sidus Heroes' },
  { src: "/images/portfolio_logos/sigmadex_logo.png", alt: "Sigmadex", company: 'Sigmadex' },
  { src: "/images/portfolio_logos/inspect_logo.png", alt: "Inspect", company: 'Inspect' },
  { src: "/images/portfolio_logos/ait_logo.png", alt: "AIT", company: 'AIT' },
  { src: "/images/portfolio_logos/zerolend_logo.png", alt: "Zerolend", company: 'Zerolend' },
  { src: "/images/portfolio_logos/kasu_logo.png", alt: "Kasu", company: 'Kasu' },
  { src: "/images/portfolio_logos/fuel.png", alt: "Fuel", company: 'Fuel' },
  { src: "/images/portfolio_logos/ape.png", alt: "Ape", company: 'Ape' },
  { src: "/images/portfolio_logos/easyx.png", alt: "EasyX", company: 'EasyX' },
  { src: "/images/portfolio_logos/pixel_verse_2.png", alt: "PixelVerse", company: 'PixelVerse' },
];

const PortfolioSection = () => {
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const marginValue = isSm ? theme.spacing(4) : theme.spacing(3);
  return (
    <Grid container spacing={0} maxWidth={"lg"} sx={{ margin: "auto" }} id="portfolio-section">
      <Grid container spacing={0} sx={{ py: 4 }}>
        {/* Columns 1-3 */}
        <Grid item xs={12} style={{ paddingBottom: theme.spacing(6) }}>
          <Typography variant="overline" color="primary">
            Portfolio
          </Typography>
          <Typography variant="h4">Who We Work With</Typography>
        </Grid>
        {logos.map((logo, index) => (
          <Grid key={index} item xs={6} sm={6} md={2}>
            <div
              style={{ position: "relative", margin: marginValue, alignItems: "center" }}
              className="portfolio-border"
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <img
                src={logo.src}
                alt={logo.company}
                class="blue-image"
                style={{ width: "100%", padding: theme.spacing(3), paddingBottom: theme.spacing(1) }}
              />
              <div style={{ display: "flex", alignItems: "center", textAlign: 'center', justifyContent: 'center'}}>
                <Typography variant="h6" sx={{ lineHeight: '1', fontSize: "10px" ,textAlign: 'center', textTransform: 'uppercase', letterSpacing: '0.1em', color: theme.palette.text.main }} style={{paddingTop: "0px", paddingLeft: "8px", paddingRight: "8px", background: "none"}}>{logo.company}</Typography>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default PortfolioSection;
