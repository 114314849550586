import React, { useEffect } from "react";
import { Grid, Typography, Divider } from "@mui/material";
import anime from "animejs";
import theme from "../theme";

const verticalLineStyle = {
  width: "2px",
  height: "64px",
  margin: "5px 0",
  backgroundColor: theme.palette.secondary.main,
};

const lineCount = 100;

const AboutSection = () => {
  // const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  // const isMd = useMediaQuery(theme.breakpoints.down("md"));
  // const isLg = useMediaQuery(theme.breakpoints.down("lg"));
  // let paddingValue = 0;
  // let widthValue = "100%";
  // if (isSm) {
  //   paddingValue = theme.spacing(9);
  //   widthValue = "55%";
  // } else if (isMd) {
  //   paddingValue = theme.spacing(9);
  //   widthValue = "55%";
  // } else if (isLg) {
  //   paddingValue = theme.spacing(9);
  //   widthValue = "55%";
  // }

  useEffect(() => {
    const grid = [1, lineCount];
    const staggersAnimation = anime
      .timeline({
        targets: ".about-line div",
        easing: "easeInOutSine",
        delay: anime.stagger(10),
        loop: true,
        autoplay: false,
      })
      .add({
        duration: 300,
        scale: 0.5,
        delay: anime.stagger(20, { grid: grid, from: "first" }),
        background: theme.palette.secondary.main,
      })
      .add({
        duration: 300,
        scale: 1,
        delay: anime.stagger(20, { grid: grid, from: "last" }),
        background: theme.palette.primary.main,
      })
      .add({
        scale: [
          {
            value: 0.1,
            easing: "easeOutSine",
            duration: 200,
            background: theme.palette.text.main,
          },
          {
            value: 1,
            easing: "easeInOutQuad",
            duration: 200,
            background: theme.palette.secondary.main,
          },
        ],
        delay: anime.stagger(20, { grid: grid, from: "first" }),
        background: theme.palette.secondary.main,
      })
      .add({
        scale: [
          {
            value: 1.9,
            easing: "easeInSine",
            duration: 200,
            background: theme.palette.text.main,
          },
          {
            value: 1,
            easing: "easeOutSine",
            duration: 200,
            background: theme.palette.secondary.main,
          },
        ],
        delay: anime.stagger(20, { grid: grid, from: "last" }),
        background: theme.palette.text.main,
      })
      .add({
        delay: anime.stagger(20, { grid: grid, from: "center" }),
        background: theme.palette.secondary.main,
      });

    staggersAnimation.play();
  }, []);
  return (
    <Grid
      container
      spacing={0}
      maxWidth={"lg"}
      className="dark-background"
      sx={{
        backgroundColor: theme.palette.background.dark,
        //minHeight: "100vh",
        margin: "auto",
      }}
    >
      <Grid container spacing={0} sx={{ pt: 4 }}>
        {/* Columns 1-3 */}
        <Grid item xs={12} lg={8}>
          <Typography variant="overline" color="primary">
            About Asteroid
          </Typography>
          <Typography variant="h5">
            We are a prominent family-backed{" "}
            <span
              style={{
                color: theme.palette.secondary.main,
                fontWeight: "bold",
              }}
            >
              blockchain fund
            </span>{" "}
            that offers a comprehensive suite of services tailored to the crypto
            ecosystem.
          </Typography>
          <Divider
            sx={{
              width: "25%",
              backgroundColor: theme.palette.primary.main,
              height: "1px",
              marginBottom: "1rem",
            }}
          />
          <Typography variant="h6">
            Our mission is simple: provide the{" "}
            <span
              style={{
                color: theme.palette.secondary.main,
                fontWeight: "bold",
              }}
            >
              best support possible
            </span>{" "}
            for blockchain startups from all angles.
          </Typography>
        </Grid>

        {/* Column 4 (Spacer) */}
        {/* <Grid item lg={1} />

        {/* Cesar */}
        {/* <Grid
          container
          item
          xs={12}
          md={6}
          lg={2}
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Grid
            item
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ paddingTop: paddingValue }}
          >
            <img
              src={"/images/cesar_color_square_small.png"}
              alt="anthony"
              style={{
                width: widthValue,
                border: `0px solid ${theme.palette.primary.main}`,
                //padding: imagePaddingValue,
                borderRadius: "50%", // Add a 1px border with the secondary main theme color
              }}
            />
          </Grid>
          <Grid item style={{ paddingTop: "8px" }}>
            <Typography variant="h5" style={{ marginBottom: "0px" }}>
              Cesar Anthony
            </Typography>
          </Grid>
          <Grid item style={{ paddingTop: "0px" }}>
            <Typography variant="smallOverline" color="primary">
              Managing Partner
            </Typography>
          </Grid>
        </Grid> */}
        {/* <Grid item lg={1} /> */}

        {/* Austin */}
        {/* <Grid
          container
          item
          xs={12}
          md={6}
          lg={2}
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Grid
            item
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ paddingTop: paddingValue }}
          >
            <img
              src={"/images/austin_square.png"}
              alt="austin_w"
              style={{
                width: widthValue,
                border: `0px solid ${theme.palette.primary.main}`,
                //padding: imagePaddingValue,
                borderRadius: "50%", // Add a 1px border with the secondary main theme color
              }}
            />
          </Grid>
          <Grid item style={{ paddingTop: "8px" }}>
            <Typography variant="h5" style={{ marginBottom: "0px" }}>
              Austin Wes
            </Typography>
          </Grid>
          <Grid item style={{ paddingTop: "0px" }}>
            <Typography variant="smallOverline" color="primary">
              Managing Partner
            </Typography>
          </Grid>
        </Grid> */}
      </Grid>
      <Grid
        container
        item
        xs={12}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ py: 8 }}
        className="about-line"
      >
        {[...Array(lineCount)].map((_, index) => (
          <div key={index} style={verticalLineStyle}></div>
        ))}
      </Grid>
    </Grid>
  );
};

export default AboutSection;