import React from "react";

function LoadingSpinner() {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "calc(100vh - 64px)", paddingBottom: '160px' }}>
      <img src="/images/asteroid_full_logo_text_only.png" alt="Loading..." style={{ maxWidth: "300px" }} className="logo" />
    </div>
  );
}

export default LoadingSpinner;
