import React, { useState } from "react";
import { AppBar, Toolbar, Grid, Box } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import theme from "../theme";

const logo_path = "/images/asteroid_full_logo_text_only.png";

const appBarStyle = {
  backgroundColor: "transparent",
  position: "static",
  boxShadow: "none", // Remove the bottom shadow
};

const logoStyle = {
  width: "200px", // Increase the logo width as needed
  marginRight: "auto", // Push the logo to the left
  display: "flex",
  alignItems: "center", // Vertically center the logo
};

const buttonContainerStyle = {
  marginLeft: "auto", // Push the buttons to the right
  display: "flex",
  alignItems: "center", // Vertically center the logo
  backgroundColor: "inherit",
};

const menuItemStyle = {
  textTransform: "uppercase",
  letterSpacing: "3px",
  fontSize: "0.9375rem",
  "&:hover": {
    backgroundColor: theme.palette.background.medium, // Change to the desired background color on hover
  },
};

function CustomAppBar() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (sectionId) => {
    if (sectionId === 'contact-section') {
      // Open the default email client with the specified email address
      window.location.href = 'mailto:contact@asteroid.capital';
    } else {
      // Scroll to the corresponding section on menu item click for other sections
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
    handleMenuClose(); // Close the menu after clicking
  };

  return (
    <>
      <AppBar sx={appBarStyle}>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item>
              <Box sx={logoStyle}>
                <img
                  src={logo_path}
                  alt="Logo"
                  style={{ width: "100%" , paddingTop: '20px', paddingBottom: '20px'}}
                />
              </Box>
            </Grid>
            <Grid item sx={buttonContainerStyle}>
              <IconButton
                size="large"
                edge="start"
                color={theme.palette.text.main}
                aria-label="menu"
                onClick={handleMenuClick}
              >
                <MenuIcon style={{ color: theme.palette.text.main }} />
              </IconButton>
              <Menu
                id="links-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={() => handleMenuItemClick('about-section')} sx={menuItemStyle}>
                  About
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('portfolio-section')} sx={menuItemStyle}>
                  Portfolio
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('contact-section')} sx={menuItemStyle}>
                  Contact
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default CustomAppBar;
