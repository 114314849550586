import React from "react";
import {
  Grid,
  Typography,
  Button,
  SvgIcon,
  useMediaQuery,
} from "@mui/material";
import { mdilArrowRightCircle } from "@mdi/light-js";
import AnimationComponent from "./AnimationComponent";
import theme from "../theme";

const HeroSection = () => {
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));
  const paddingValue = isLg ? "15vh" : 0;
  let animPaddingTopValue = "25vh";
  let animPaddingBottomValue = "5vh";

  if (isXs) {
    animPaddingTopValue = "15vh";
    animPaddingBottomValue = "5vh";
  } else if (isSm) {
    animPaddingTopValue = "15vh";
    animPaddingBottomValue = "5vh";
  } else if (isMd) {
    animPaddingTopValue = "15vh";
    animPaddingBottomValue = "5vh";
  } else if (isLg) {
    animPaddingTopValue = "10vh";
  } else {
    animPaddingTopValue = "0vh";
  }

  const handleContactClick = () => {
    // Open the default email client with the specified email address
    window.location.href = "mailto:contact@asteroid.capital";
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={0}
      maxWidth={"lg"}
      style={{
        margin: "auto",
        minHeight: "calc(100vh - 64px)",
        paddingBottom: "64px",
      }}
    >
      <Grid item xs={12} lg={6}>
        <Typography
          variant="h2"
          gutterBottom
          style={{ fontWeight: "500", paddingTop: paddingValue }}
        >
          Inspiring Early Founders
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          endIcon={
            <SvgIcon>
              <path d={mdilArrowRightCircle} />
            </SvgIcon>
          }
          onClick={handleContactClick}
        >
          Launch a Project
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        style={{
          justifyContent: "center",
          display: "flex",
          paddingTop: animPaddingTopValue,
          paddingBottom: animPaddingBottomValue,
        }}
      >
        <AnimationComponent />
      </Grid>
    </Grid>
  );
};

export default HeroSection;
