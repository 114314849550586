import React from "react";
import {
  Grid,
  Typography,
  Divider,
  IconButton,
  Button,
  Snackbar,
} from "@mui/material";
import theme from "../theme";
import { Telegram, LinkedIn, MailOutline } from "@mui/icons-material";
import XIcon from '@mui/icons-material/X';

const logo_path = "/images/asteroid_full_logo_text_only.png";

const iconStyle = {
  color: theme.palette.text.main,
  fontSize: "2rem", // Adjust the size as needed
};

const Footer = () => {
  const handleFooterItemClick = (sectionId) => {
    if (sectionId === "contact-section") {
      // Open the default email client with the specified email address
      window.location.href = "mailto:contact@asteroid.capital";
    } else {
      // Scroll to the corresponding section on menu item click for other sections
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const email = "contact@asteroid.capital"; // Replace with your email address
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const handleIconClick = (url) => () => {
    if (url === "copy-email") {
      // Copy email to clipboard
      navigator.clipboard
        .writeText(email)
        .then(() => setSnackbarOpen(true))
        .catch((err) => console.error("Error copying to clipboard:", err));
    } else {
      // Open the social media profile URL in a new tab
      window.open(url, "_blank");
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Grid
      container
      spacing={0}
      maxWidth={"lg"}
      className="dark-background"
      sx={{ backgroundColor: theme.palette.background.dark, margin: "auto" }}
    >
      <Grid container spacing={0} sx={{ pt: 4 }}>
        {/* Columns 1-3 */}
        <Grid item xs={12} style={{ justifyContent: "center" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingBottom: theme.spacing(2),
            }}
          >
            <img
              src={logo_path}
              alt="Logo"
              width="220px"
            />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingBottom: theme.spacing(4),
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              size="small"
              style={{ border: `0px solid green`, paddingLeft: "12px" }}
              onClick={() => handleFooterItemClick("about-section")}
            >
              About
            </Button>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ mx: 2, backgroundColor: theme.palette.text.main }}
            />
            <Button
              variant="outlined"
              color="primary"
              size="small"
              style={{ border: `0px solid`, paddingLeft: "12px" }}
              onClick={() => handleFooterItemClick("portfolio-section")}
            >
              Portfolio
            </Button>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ mx: 2, backgroundColor: theme.palette.text.main }}
            />
            <Button
              variant="outlined"
              color="primary"
              size="small"
              style={{ border: `0px solid green`, paddingLeft: "12px" }}
              onClick={() => handleFooterItemClick("contact-section")}
            >
              Contact
            </Button>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={snackbarOpen}
            autoHideDuration={3000} // 3 seconds
            onClose={handleSnackbarClose}
            message="Email copied to clipboard!"
          />
          <Divider
            sx={{
              width: "100%",
              backgroundColor: theme.palette.text.main,
              height: "1px",
              marginBottom: "1rem",
            }}
          />
        </Grid>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          style={{
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(4),
          }}
        >
          {/* Right Grid */}
          <Grid item xs={12} lg={6}>
            <Grid
              container
              direction="column"
              alignItems={{ xs: "center", lg: "flex-start" }}
            >
              {/* Your content for the right grid */}
              <Grid item>
                <div>
                  <IconButton
                    onClick={handleIconClick(
                      "https://t.me/cesarajc"
                    )}
                  >
                    <Telegram style={iconStyle} />
                  </IconButton>
                  <IconButton
                    onClick={handleIconClick(
                      "https://www.linkedin.com/company/asteroidfund/"
                    )}
                  >
                    <LinkedIn style={iconStyle} />
                  </IconButton>
                  <IconButton
                    onClick={handleIconClick(
                      "https://twitter.com/asteroidcapital"
                    )}
                  >
                    <XIcon style={iconStyle} />
                  </IconButton>
                  <IconButton onClick={handleIconClick("copy-email")}>
                    <MailOutline style={iconStyle} />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid
              container
              direction="column"
              alignItems={{ xs: "center", lg: "flex-end" }}
            >
              {/* Your content for the left grid */}
              <Grid item>
                <Typography variant="h6" sx={{ mb: 0 }}>
                  © 2023 Asteroid Capital
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
