import React, { useState, useEffect } from "react";
import {
  CssBaseline,
  ThemeProvider,
  Container,
} from "@mui/material";
import theme from "./theme";
import CustomAppBar from "./components/CustomAppBar";
import HeroSection from "./components/HeroSection";
import AboutSection from "./components/AboutSection";
import ServicesSection from "./components/ServicesSection";
import MetricsSection from "./components/MetricsSection";
import PortfolioSection from "./components/PortfolioSection";
// import TimelineSection from "./components/TimelineSection";
import AgencySection from "./components/AgencySection";
import Footer from "./components/Footer";
import BackgroundContainer from "./components/BackgroundContainer";
import LoadingSpinner from "./components/LoadingSpinner"; // Import the LoadingSpinner component
import "./App.css"; // You can import your CSS styles here if needed

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Adjust the delay time as needed

    // Clear the timeout when component unmounts
    return () => clearTimeout(timer);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BackgroundContainer>
        <CustomAppBar />
        {loading ? (
          // Render LoadingSpinner component here
          <LoadingSpinner />
        ) : (
          <Container maxWidth={false} style={{ margin: "auto", overflowX: 'hidden' }}>
            {/* defaults to negative margin here */}
            <HeroSection />
            <AboutSection />
            <ServicesSection />
            <MetricsSection />
            <PortfolioSection />
            {/* <TimelineSection /> */}
            <AgencySection />
            <Footer />
          </Container>
        )}
      </BackgroundContainer>
    </ThemeProvider>
  );
}

export default App;
