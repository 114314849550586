import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import anime from "animejs";
import theme from "../theme";

const verticalLineStyle = {
  width: "2px",
  height: "100%",
  marginRight: "1rem",
  backgroundColor: theme.palette.secondary.main,
  overflow: "hidden",
  position: "relative",
  transform: "translateY(-100%)",
};

const verticalOverlayLineStyle = {
  width: "2px",
  height: "100%",
  marginRight: "1rem",
  backgroundColor: theme.palette.text.main,
  position: "absolute",
  left: "0",
  top: "0",
};

const SingleMetric = ({ name, value, spacing }) => (
  <Grid
    container
    item
    xs={12}
    lg={6}
    display="flex"
    alignItems="center"
    position="relative"
    overflow="hidden"
    marginBottom={{ xs:theme.spacing(spacing), lg:theme.spacing(0)}}
  >
    <div style={verticalOverlayLineStyle} className="metric-overlay-line"></div>
    <div style={verticalLineStyle} className="metric-line"></div>
    <div>
      <Typography variant="h3" style={{ lineHeight: '1'}}>
        {value}
      </Typography>
      <Typography
        variant="overline"
        style={{
          display: "block",
          color: theme.palette.secondary.main,
          marginBottom: "0",
          lineHeight: '1',
        }}
        gutterBottom
      >
        {name}
      </Typography>
    </div>
  </Grid>
);

const MetricsSection = () => {
  useEffect(() => {
    const getRandomDelay = () => Math.floor(Math.random() * 2000) + 1000; // Adjust the range as needed
    const staggersAnimation = anime
      .timeline({
        targets: ".metric-line",
        easing: "easeInOutSine",
        delay: anime.stagger(getRandomDelay(), { start: 500 }),
        loop: true,
        autoplay: false,
      })
      .add({
        duration: 600,
        translateY: "100%",
        //delay: anime.stagger(6000, { grid: grid, from: "last" }),
      })
      .add({
        duration: 30,
        //delay: anime.stagger(6000, { grid: grid, from: "last" }),
      });

    staggersAnimation.play();
  }, []);
  return (
    <Grid
      container
      spacing={0}
      maxWidth={"lg"}
      className="dark-background"
      sx={{
        backgroundColor: theme.palette.background.dark,
        margin: "auto",
      }}
    >
      <Grid container spacing={0} sx={{ my: 6 }}>
        <SingleMetric name="Projects Funded" value="40+" spacing={4}/>
        <SingleMetric name="Partners" value="140+" spacing={4}/>
        {/* <SingleMetric name="Fund ROI" value="405x" spacing={0}/> */}
      </Grid>
    </Grid>
  );
};

export default MetricsSection;
