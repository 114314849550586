import React, { useEffect } from "react";
import anime from "animejs";
import theme from "../theme";

const AnimationComponent = () => {
  useEffect(() => {
    // Your anime.js animation code here
    const staggerVisualizerEl = document.querySelector(".stagger-visualizer");
    const fragment = document.createDocumentFragment();
    const grid = [17, 17];
    const col = grid[0];
    const row = grid[1];
    const numberOfElements = col * row;
    console.log(theme.palette.primary.main);

    for (let i = 0; i < numberOfElements; i++) {
      fragment.appendChild(document.createElement("div"));
    }

    staggerVisualizerEl.appendChild(fragment);

    const staggersAnimation = anime
      .timeline({
        targets: ".stagger-visualizer div",
        easing: "easeInOutSine",
        delay: anime.stagger(50),
        loop: true,
        autoplay: false,
      })
      .add({
        translateY: [
          {
            value: anime.stagger("-10px", {
              grid: grid,
              from: "last",
              axis: "y",
            }),
          },
          {
            value: anime.stagger("0px", {
              grid: grid,
              from: "last",
              axis: "y",
            }),
          },
        ],
        duration: 1000,
        //scale: 5,
        delay: anime.stagger(200, { grid: grid, from: "center" }),
        background: theme.palette.secondary.main, //theme.palette.secondary.main,
      })
      .add({
        scale: [
          { value: 0.1, easing: "easeOutSine", duration: 500 },
          { value: 1, easing: "easeInOutQuad", duration: 1200 },
        ],
        delay: anime.stagger(200, { grid: grid, from: "center" }),
        background: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      })
      // .add({
      //   translateX: anime.stagger(".25rem", {
      //     grid: grid,
      //     from: "center",
      //     axis: "x",
      //   }),
      //   translateY: anime.stagger(".25rem", {
      //     grid: grid,
      //     from: "center",
      //     axis: "y",
      //   }),
      //   rotate: 0,
      //   scaleX: 1.5,
      //   scaleY: 0.25,
      //   delay: anime.stagger(4, { from: "center" }),
      //   background: theme.palette.secondary.main,
      // })
      // .add({
      //   rotate: anime.stagger([90, 0], { grid: grid, from: "center" }),
      //   delay: anime.stagger(50, { grid: grid, from: "center" }),
      //   background: theme.palette.primary.main,
      // })
      // .add({
      //   translateX: 0,
      //   translateY: 0,
      //   scale: 0.5,
      //   scaleX: 1,
      //   rotate: 180,
      //   duration: 1000,
      //   delay: anime.stagger(100, { grid: grid, from: "center" }),
      //   background: theme.palette.secondary.main,
      // })
      .add({
        scaleY: 1,
        scale: 1,
        delay: anime.stagger(20, { grid: grid, from: "center" }),
        background: theme.palette.primary.main,
      })
      .add({
        translateX: anime.stagger(".3rem", {
          grid: grid,
          from: "center",
          axis: "x",
        }),
        translateY: anime.stagger(".3rem", {
          grid: grid,
          from: "center",
          axis: "y",
        }),
        rotate: anime.stagger([90, 0], { grid: grid, from: "center" }),
        delay: anime.stagger(50, { grid: grid, from: "center" }),
        background: theme.palette.primary.main,
      })
      .add({
        translateX: anime.stagger("0rem", {
          grid: grid,
          from: "center",
          axis: "x",
        }),
        translateY: anime.stagger("0rem", {
          grid: grid,
          from: "center",
          axis: "y",
        }),
        rotate: anime.stagger(0, { grid: grid, from: "center" }),
        delay: anime.stagger(50, { grid: grid, from: "last" }),
        background: theme.palette.text.main,
        borderColor: theme.palette.text.main,
      });
      // .add({
      //   delay: anime.stagger(20, { grid: grid, from: "center" }),
      //   background: theme.palette.text.main,
      //   borderColor: theme.palette.text.main,
      // });
    staggersAnimation.play();
  }, []);

  return <div className="stagger-visualizer"></div>;
};

export default AnimationComponent;
