import { createTheme } from "@mui/material/styles";

// const textBlue = "#ADDDFF";
// const mint = "#52B788";
// const darkRed = "#C1666B";
// const lightBlue = "#080731";
// const mediumBlue = "#080731";
// const darkBlue = "#01061C";

const textBlue = "#FFFFFF";
const mint = "#52B788";
//const mint = "#183A40";
const darkRed = "#52B788";
const lightBlue = "#0a0a0a";
const mediumBlue = "#0a0a0a";
const darkBlue = "#000000";

const theme = createTheme({
  typography: {
    smallOverline: {
      fontSize: "0.625rem", // Set the desired font size
      letterSpacing: "0.2em", // Set the desired letter spacing
      textTransform: "uppercase", // Mimicking the 'overline' style
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          //fontFamily: "Michroma, Arial, sans-serif", // Header font
          color: textBlue,
          fontSize: "5rem",
        },
        h2: {
          //fontFamily: "Michroma, Arial, sans-serif", // Header font
          color: textBlue,
        },
        h3: {
          //fontFamily: "Michroma, Arial, sans-serif", // Header font
          color: textBlue,
          marginBottom: "1rem",
        },
        h4: {
          //fontFamily: "Michroma, Arial, sans-serif", // Header font
          color: textBlue,
          marginBottom: "1rem",
        },
        h5: {
          //fontFamily: "Michroma, Arial, sans-serif", // Header font
          color: textBlue,
          marginBottom: "1rem",
        },
        h6: {
          //fontFamily: "Michroma, Arial, sans-serif", // Header font
          color: textBlue,
          marginBottom: "1rem",
        },
        body: {
          color: textBlue,
        },
        overline: {
          //fontFamily: "Michroma, Arial, sans-serif", // Header font
          color: textBlue,
          fontSize: "20px",
          letterSpacing: "0.3em", // Adjust the value as needed
          lineHeight: "1.7",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlinedPrimary: {
          //backgroundColor: mint,
          color: textBlue, // Change the text color for primary contained buttons
          borderRadius: "50px",
          // Add any other style overrides for primary contained buttons here
          border: `1px solid ${mint}`, // Set the border color to green
          letterSpacing: "3px",
          paddingLeft: '16px',
          transition: "box-shadow 0.3s", // Add a smooth transition for the box-shadow property

          "&:hover": {
            boxShadow: `0 0 10px 2px ${mint}`, // Add a box-shadow effect on hover
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: mint, //mint
    },
    secondary: {
      main: darkRed, //dark red
    },
    background: {
      light: lightBlue, //medium blue
      medium:  mediumBlue,
      dark: darkBlue, // darker blue
    },
    text: {
      main: textBlue, //light blue
    },
  },
});

export default theme;
