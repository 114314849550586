import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  SvgIcon,
  // useMediaQuery,
} from "@mui/material";
import { mdilArrowRightCircle } from "@mdi/light-js";
import anime from "animejs";
import theme from "../theme";

const agencyLineStyle = {
  width: "1px",
  // flexGrow: "1",
  marginRight: "1rem",
  backgroundColor: theme.palette.primary.main,
};

const AgencySection = () => {
  useEffect(() => {
    // Animation configuration
    const animationConfigTop = {
      targets: [".animated-path-top", "feTurbulence"],
      d: [
        "M 146.5,-0.5 C 158.5,-0.5 170.5,-0.5 182.5,-0.5C 231.519,119.571 280.519,239.571 329.5,359.5C 329.5,360.167 329.5,360.833 329.5,361.5C 299.916,332.416 270.416,303.083 241,273.5C 215.495,212.491 189.995,151.491 164.5,90.5C 139.005,151.491 113.505,212.491 88,273.5C 58.5841,303.083 29.0841,332.416 -0.5,361.5C -0.5,360.833 -0.5,360.167 -0.5,359.5C 48.4814,239.571 97.4814,119.571 146.5,-0.5 Z",
        "M 149.601 326.817 C 159.822 320.485 173.031 317.079 181.957 327.207 C 229.069 393.972 277.679 267.223 329.5 359.5 C 329.5 360.167 329.5 360.833 329.5 361.5 C 299.916 332.416 271.818 369.942 242.402 340.359 C 216.897 279.35 193.946 400.954 168.451 339.963 C 142.956 400.954 112.68 279.587 87.175 340.596 C 54.924 361.63 29.084 332.416 -0.5 361.5 C -0.5 360.833 -0.5 360.167 -0.5 359.5 C 48.172 266.044 100.092 409.048 149.601 326.817 Z",
        "M 146.5,-0.5 C 158.5,-0.5 170.5,-0.5 182.5,-0.5C 231.519,119.571 280.519,239.571 329.5,359.5C 329.5,360.167 329.5,360.833 329.5,361.5C 299.916,332.416 270.416,303.083 241,273.5C 215.495,212.491 189.995,151.491 164.5,90.5C 139.005,151.491 113.505,212.491 88,273.5C 58.5841,303.083 29.0841,332.416 -0.5,361.5C -0.5,360.833 -0.5,360.167 -0.5,359.5C 48.4814,239.571 97.4814,119.571 146.5,-0.5 Z",
        "M 160.154 23.666 C 164.359 16.526 166.741 16.405 172.324 23.795 C 210.506 146.686 263.447 251.26 329.5 359.5 C 329.5 360.167 329.5 360.833 329.5 361.5 C 299.916 332.416 292.263 290.802 262.847 261.219 C 233.215 202.971 191.911 84.059 166.416 23.068 C 92.378 178.457 89.449 197.511 63.944 258.52 C 34.528 288.103 29.084 332.416 -0.5 361.5 C -0.5 360.833 -0.5 360.167 -0.5 359.5 C 66.731 247.513 106.113 144.625 160.154 23.666 Z",
      ],
      easing: "easeInOutExpo",
      loop: true,
      duration: 5000,
      direction: "alternate",
      baseFrequency: ["0 0", "0.02 0.01", "0 0", "0.02 0.01"],
    };

    // Play the animation
    anime(animationConfigTop);

    // Animation configuration
    const animationConfigBottom = {
      targets: [".animated-path-bottom", "feTurbulence"],
      d: [
        "M 321.5,373.5 C 320.833,373.5 320.167,373.5 319.5,373.5C 272.905,343.423 226.405,313.09 180,282.5C 175.127,266.714 169.96,251.047 164.5,235.5C 159.04,251.047 153.873,266.714 149,282.5C 102.595,313.09 56.0946,343.423 9.5,373.5C 8.83333,373.5 8.16667,373.5 7.5,373.5C 41.1738,342.328 75.3404,311.662 110,281.5C 128.172,239.158 146.338,196.825 164.5,154.5C 182.662,196.825 200.828,239.158 219,281.5C 253.66,311.662 287.826,342.328 321.5,373.5 Z",
        "M 321.5 373.5 C 320.833 373.5 320.167 373.5 319.5 373.5 C 272.905 343.423 229.284 406.135 182.879 375.545 C 178.006 359.759 170.291 374.211 164.831 358.664 C 159.371 374.211 154.473 358.716 149.6 374.502 C 103.195 405.092 56.095 343.423 9.5 373.5 C 8.833 373.5 8.167 373.5 7.5 373.5 C 41.174 342.328 74.825 410.076 109.485 379.914 C 127.657 337.572 145.222 418.392 163.384 376.067 C 181.546 418.392 201.122 335.802 219.294 378.144 C 253.954 408.306 287.826 342.328 321.5 373.5 Z",
        "M 321.5,373.5 C 320.833,373.5 320.167,373.5 319.5,373.5C 272.905,343.423 226.405,313.09 180,282.5C 175.127,266.714 169.96,251.047 164.5,235.5C 159.04,251.047 153.873,266.714 149,282.5C 102.595,313.09 56.0946,343.423 9.5,373.5C 8.83333,373.5 8.16667,373.5 7.5,373.5C 41.1738,342.328 75.3404,311.662 110,281.5C 128.172,239.158 146.338,196.825 164.5,154.5C 182.662,196.825 200.828,239.158 219,281.5C 253.66,311.662 287.826,342.328 321.5,373.5 Z",
        "M 321.5 373.5 C 320.833 373.5 320.167 373.5 319.5 373.5 C 272.905 343.423 217.108 307.535 197.943 266.982 C 193.07 251.196 170.044 178.671 164.584 163.124 C 159.124 178.671 124.55 258.964 123.44 274.75 C 82.725 312.337 56.095 343.423 9.5 373.5 C 8.833 373.5 8.167 373.5 7.5 373.5 C 41.174 342.328 75.34 311.662 110 281.5 C 128.172 239.158 146.338 196.825 164.5 154.5 C 182.662 196.825 195.895 245.114 214.067 287.456 C 248.727 317.618 287.826 342.328 321.5 373.5 Z",
      ],
      easing: "easeInOutExpo",
      loop: true,
      duration: 5000,
      direction: "alternate",
      baseFrequency: ["0 0", "0.02 0.01", "0 0", "0.02 0.01"],
    };

    // Play the animation
    anime(animationConfigBottom);
  }, []);

  const handleLearnMoreClick = () => {
    // Open the default email client with the specified email address
    window.location.href =
      "mailto:contact@asteroid.capital?subject=Move%20with%20Asteroid";
  };

  // const isLg = useMediaQuery(theme.breakpoints.down("lg"));
  // const topValue = isLg ? 8 : 0;

  return (
    <Grid container spacing={0} maxWidth={"lg"} sx={{ margin: "auto" }}>
      <Grid
        container
        spacing={0}
        sx={{ pt: 4, pb: 4 }}
        display="flex"
        alignItems="start"
      >
        {/* Columns 1-3 */}
        <Grid item xs={12} lg={12} display="flex" alignItems="stretch">
          <div style={agencyLineStyle}></div>
          <div>
            <Typography variant="overline" color="primary">
              Need Additional Services?
            </Typography>
            <Typography variant="h5" style={{ marginTop: theme.spacing(1) }}>
              <span
                style={{
                  color: theme.palette.secondary.main,
                  fontWeight: "bold",
                }}
              >
                Move With Asteroid
              </span>{" "}
              is a full service agency that will take your project to the next
              level
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              endIcon={
                <SvgIcon>
                  <path d={mdilArrowRightCircle} />
                </SvgIcon>
              }
              onClick={handleLearnMoreClick}
            >
              Learn More
            </Button>
          </div>
        </Grid>

        {/* Column 4 (Spacer) */}
        {/* <Grid item lg={1} /> */}

        {/* Columns 5-6 */}
        {/* <Grid
          container
          item
          xs={12}
          lg={2}
          sx={{ paddingTop: theme.spacing(topValue) }}
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={0}
          minHeight="10em"
        >
          <SvgIcon
            viewBox="0 0 340 340"
            style={{ height: "100%", fontSize: "7em" }}
          >
            <svg width="0" height="100%">
              <defs>
                <filter id="turbulence" x="0" y="0" width="100%" height="100%">
                  <feTurbulence
                    type="fractalNoise"
                    baseFrequency="0 0"
                    numOctaves="5"
                  />
                  <feDisplacementMap in="SourceGraphic" scale="30" />
                </filter>
              </defs>
            </svg>
            <g filter="url(#turbulence)">
              <path
                fill={theme.palette.text.main}
                className="animated-path-top"
                d="M 146.5,-0.5 C 158.5,-0.5 170.5,-0.5 182.5,-0.5C 231.519,119.571 280.519,239.571 329.5,359.5C 329.5,360.167 329.5,360.833 329.5,361.5C 299.916,332.416 270.416,303.083 241,273.5C 215.495,212.491 189.995,151.491 164.5,90.5C 139.005,151.491 113.505,212.491 88,273.5C 58.5841,303.083 29.0841,332.416 -0.5,361.5C -0.5,360.833 -0.5,360.167 -0.5,359.5C 48.4814,239.571 97.4814,119.571 146.5,-0.5 Z"
              />
              <path
                fill="#52b787"
                className="animated-path-bottom"
                d="M 321.5,373.5 C 320.833,373.5 320.167,373.5 319.5,373.5C 272.905,343.423 226.405,313.09 180,282.5C 175.127,266.714 169.96,251.047 164.5,235.5C 159.04,251.047 153.873,266.714 149,282.5C 102.595,313.09 56.0946,343.423 9.5,373.5C 8.83333,373.5 8.16667,373.5 7.5,373.5C 41.1738,342.328 75.3404,311.662 110,281.5C 128.172,239.158 146.338,196.825 164.5,154.5C 182.662,196.825 200.828,239.158 219,281.5C 253.66,311.662 287.826,342.328 321.5,373.5 Z"
              />
            </g>
          </SvgIcon>
        </Grid> */}
        {/* <Grid item lg={1} /> */}
      </Grid>
    </Grid>
  );
};

export default AgencySection;
